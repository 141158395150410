<template>
    <footer class="container mx-auto bg-white py-8 border-t border-gray-400">
      <div class="container flex px-4 xl:px-0 lg:py-8">
        <div class="w-full mx-auto flex flex-wrap">
          <div class="md:flex text-left w-full lg:w-5/6">
            <div class="md:w-1/3 px-3 md:px-0">
              <h3 class="font-bold text-gray-900">{{ jsonMisc.footer.head }}</h3>
              <p class="py-4">{{ jsonMisc.footer.body }}</p>
            </div>
            <div class="w-full md:w-1/3 px-4 md:px-6">
              <h5 class="text-md font-bold py-2 md:py-0">Contact Information</h5>
              <div class="flex flex-col text-white bg-orange-1100 p-6 my-t md:mt-6 h-64 rounded-lg relative">
                <div class="">
                  <h3 class="text-xl font-bold pb-2">Contact Us</h3>
                  <p class="text-sm"><b>For more Info call us on : </b><br/>
                    <a :href="`tel:${jsonMisc.contactUsCard.mobile1}`"><PhoneIcon /><span class="text-grey-darker text-left">+91 {{ jsonMisc.contactUsCard.mobile1 }}</span></a>/<a :href="`tel:${jsonMisc.contactUsCard.mobile2}`"><PhoneIcon /><span class="text-grey-darker text-left">+91 {{ jsonMisc.contactUsCard.mobile2 }}</span></a>
                  </p>
                  <p class="text-sm"><b>Or email us : </b><br/>
                    <a :href="`mailTo:${jsonMisc.contactUsCard.email1}`"><MailIcon /><span class="text-grey-darker text-left">{{ jsonMisc.contactUsCard.email1 }}</span></a>/<br/><a :href="`mailTo:${jsonMisc.contactUsCard.email2}`"><MailIcon /><span class="text-grey-darker text-left">{{ jsonMisc.contactUsCard.email2 }}</span></a>
                  </p>
                </div>
                <div class="mt-auto pb-6 flex justify-between">
                  <span @click="modalOpen()" class="text-sm cursor-pointer">Contact Us</span>
                  <svg  @click="modalOpen()" class="w-6 h-6 cursor-pointer" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 h-auto rounded-lg relative px-4 md:px-0">
              <h5 class="text-md font-bold py-2 md:py-0">Company Location</h5>
              <div class="border border-gray-700 module-content clearfix rounded-lg md:mt-6">
                <iframe :src="jsonMisc.googleMap.googleMapUrl" width="100%" frameborder="0" class="rounded-lg h-64" allowfullscreen=""></iframe>
              </div>
            </div>
          </div>
          <div class="flex w-full lg:w-1/6 lg:justify-end text-left lg:text-right">
            <div class="px-3 md:px-0">
              <h3 class="font-bold text-gray-900">Social</h3>
              <ul class="list-reset items-center pt-3">
                <li>
                  <div class="flex text-gray-700">
                    <a
                      :href="jsonMisc.socialMediaLinks.facebook"
                      target="_blank"
                      class="crayons-link crayons-link--secondary mr-2"
                      rel="noopener"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        role="img"
                        aria-labelledby="ab42ak1webofw5omjkn3m7am5lyhb10n"
                        class="crayons-icon"
                      >
                        <title id="ab42ak1webofw5omjkn3m7am5lyhb10n">
                          Facebook
                        </title>
                        <path
                          d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"
                          fill="#4a5568"
                        ></path>
                      </svg>
                    </a>
                    <!-- <a
                      href="https://plus.google.com/u/0/115673427240042462076"
                      target="_blank"
                      class="crayons-link crayons-link--secondary mx-2"
                      rel="noopener"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        role="img"
                        aria-labelledby="ab42ak1webofw5omjkn3m7am5lyhb10n"
                        class="crayons-icon"
                      >
                        <title id="ab42ak1webofw5omjkn3m7am5lyhb10n">
                          Google-plus
                        </title>
                        <path
                          d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-10.333 16.667c-2.581 0-4.667-2.087-4.667-4.667s2.086-4.667 4.667-4.667c1.26 0 2.313.46 3.127 1.22l-1.267 1.22c-.347-.333-.954-.72-1.86-.72-1.593 0-2.893 1.32-2.893 2.947s1.3 2.947 2.893 2.947c1.847 0 2.54-1.327 2.647-2.013h-2.647v-1.6h4.406c.041.233.074.467.074.773-.001 2.666-1.787 4.56-4.48 4.56zm11.333-4h-2v2h-1.334v-2h-2v-1.333h2v-2h1.334v2h2v1.333z"
                          fill="#4a5568"
                        ></path>
                      </svg>
                    </a> -->
                    <a
                      :href="jsonMisc.socialMediaLinks.twitter"
                      target="_blank"
                      class="crayons-link crayons-link--secondary mx-2"
                      rel="noopener"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        role="img"
                        aria-labelledby="at9o9t2kxultxzdzepppn59ck47f06fc"
                        class="crayons-icon"
                      >
                        <title id="at9o9t2kxultxzdzepppn59ck47f06fc">
                          Twitter
                        </title>
                        <path
                          d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z"
                          fill="#4a5568"
                        ></path>
                      </svg>
                    </a>
                    <a
                      :href="jsonMisc.socialMediaLinks.linkedin"
                      target="_blank"
                      class="crayons-link crayons-link--secondary mx-2"
                      rel="noopener"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        role="img"
                        aria-labelledby="at9o9t2kxultxzdzepppn59ck47f06fc"
                        class="crayons-icon"
                      >
                        <title id="at9o9t2kxultxzdzepppn59ck47f06fc">
                          Linkedin
                        </title>
                        <path
                          d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                          fill="#4a5568"
                        ></path>
                      </svg>
                    </a>
                    <a
                      :href="jsonMisc.socialMediaLinks.instagram"
                      target="_blank"
                      class="crayons-link crayons-link--secondary ml-2"
                      rel="noopener"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        role="img"
                        aria-labelledby="a324dh2axynlcj0cu0wcdnfsuhmcw1af"
                        class="crayons-icon"
                      >
                        <title id="a324dh2axynlcj0cu0wcdnfsuhmcw1af">
                          Instagram
                        </title>
                        <path
                          d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z"
                          fill="#4a5568"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
// import jsonData from "@/assets/json/data.json";

import {
  // SearchIcon,
  // InfoIcon,
  // UsersIcon,
  // XCircleIcon,
} from "vue-feather-icons";

export default {
  name: "Header",
  components: {
    // SearchIcon,
    // InfoIcon,
    // UsersIcon,
    // XCircleIcon,
  },
  data() {
    return {
      productsInCart: [],
      folderRDrawerOpen: false,
      isEnquired: false,
    };
  },
  props: {
    jsonMisc: Object,
    isModalOpen: Boolean,
  },
  methods: {
    checkOut() {
      this.folderRDrawerOpen = true;
      // console.log("this.folderRDrawerOpen ", this.folderRDrawerOpen);
    },
    modalOpen() {
      this.$parent.isModalOpen = true;
      // console.log("this.folderRDrawerOpen ", this.folderRDrawerOpen);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
