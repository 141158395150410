<template>
  <div class="bg-white text-gray-600 work-sans leading-normal text-base tracking-normal">
    <!--floating cart-->
    <div v-if="productsInCart.length && isVisible && !isCheckout" class="fixed bottom-0 left-0 mb-5 rounded-r-full w-12 flex h-6 items-center justify-end z-20">
      <div class="h-8 w-8 absolute animate-ping bg-orange-400 rounded-full" />
      <div @click="checkOut()" class="h-8 w-8 rounded-full border border-gray-500 bg-orange-100 p-1 z-20">
        <span
          class="absolute w-4 h-4 flex items-center justify-center bg-orange-1100 font-bold text-white rounded-full z-20"
          style="font-size: 10px; margin-top: -10px; margin-left: 15px"
          >{{ productsInCart.length }}</span
        >
        <svg
          class="fill-current hover:text-black"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M21,7H7.462L5.91,3.586C5.748,3.229,5.392,3,5,3H2v2h2.356L9.09,15.414C9.252,15.771,9.608,16,10,16h8 c0.4,0,0.762-0.238,0.919-0.606l3-7c0.133-0.309,0.101-0.663-0.084-0.944C21.649,7.169,21.336,7,21,7z M17.341,14h-6.697L8.371,9 h11.112L17.341,14z"
          />
          <circle cx="10.5" cy="18.5" r="1.5" />
          <circle cx="17.5" cy="18.5" r="1.5" />
        </svg>
      </div>
    </div>
    <!--scroll to top-->
    <div v-show="isVisible && !isCheckout" class="fixed bottom-0 right-0 mb-5 mr-2 rounded-r-full w-12 flex h-6 items-center justify-end z-20">
      <ArrowUpIcon @click="scrollTop" class="h-8 w-8 cursor-pointer rounded-full border border-gray-500 bg-white p-1 z-20" />
    </div>
    <!--Navbar-->
    <nav id="header" class="w-full z-30 top-0">
      <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-6 py-3">
        <label @click="menuToggle()" class="cursor-pointer md:hidden block order-3 left-0">
          <svg
            class="fill-current text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </label>
        <input class="hidden" type="checkbox" id="menu-toggle" />
        <div id="menu" class="hidden md:flex md:items-center md:w-auto w-full order-4 md:order-1 z-30">
          <nav class="grid lg:flex md:my-3 justify-center">
            <ul class="md:flex items-center justify-between text-base text-gray-700 mt-4 lg:mr-2 md:pt-0">
              <li class="md:mx-auto" :class="{'font-bold': this.$route.name === 'home'}">
                <router-link to="/" class="hidden md:block no-underline hover:text-black hover:underline py-2">
                  <img key="1111" src="@/assets/images/web-house.svg" width="20px"/>
                </router-link>
                <label @click="menuToggle()"><router-link to="/" class="block md:hidden no-underline hover:text-black py-1">Home</router-link></label>
              </li>
            </ul>
            <ul class="md:flex items-center justify-between text-base text-gray-700  lg:mt-4 md:pt-0 md:text-sm lg:text-base">
              <li class="lg:w-20" :class="{'font-bold': this.$route.name === 'about'}">
                <router-link to="/about" class="hidden md:block no-underline hover:text-black hover:underline py-1 text-left lg:text-center">About Us</router-link>
                <router-link to="/about" class="block md:hidden no-underline hover:text-black py-1"><label @click="menuToggle()">About Us</label></router-link>
              </li>
              <li class="lg:w-20" :class="{'font-bold': this.$route.name === 'products'}">
                <router-link to="/products" class="hidden md:block no-underline hover:text-black hover:underline py-1 md:mx-2 lg:mx-0">Products</router-link>
                <router-link to="/products" class="block md:hidden no-underline hover:text-black py-1"><label @click="menuToggle()">Products</label></router-link>
              </li>
              <li class="block md:hidden">
                <span @click="openContactUsModal()" class="block md:hidden no-underline hover:text-black py-1"><label @click="menuToggle()">Contact Us</label></span>
              </li>
              <li class="hidden md:block lg:w-24">
                <span @click="openContactUsModal()" class="no-underline hover:text-black cursor-pointer py-1">Contact Us</span>
              </li>
            </ul>
          </nav>
        </div>
        <div class="md:absolute inset-x-0 order-1 w-full md:w-auto z-0">
          <router-link to="/">
            <img :src="jsonMisc.logos.mainLogo" alt="emmkay industries logo" class="mx-auto pb-2 md:pb-0" />
          </router-link>
        </div>
        <div class="order-3 flex items-center z-20" id="nav-content">
          <ul class="list-reset items-center">
            <li>
              <div class="flex text-gray-700">
                <a
                  :href="jsonMisc.socialMediaLinks.facebook"
                  target="_blank"
                  class="crayons-link crayons-link--secondary mr-1"
                  rel="noopener"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    role="img"
                    aria-labelledby="ab42ak1webofw5omjkn3m7am5lyhb10n"
                    class="crayons-icon"
                  >
                    <title id="ab42ak1webofw5omjkn3m7am5lyhb10n">
                      Facebook
                    </title>
                    <path
                      d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"
                      fill="#4a5568"
                    ></path>
                  </svg>
                </a>
                <!-- <a
                  href="https://plus.google.com/u/0/115673427240042462076"
                  target="_blank"
                  class="crayons-link crayons-link--secondary mx-1"
                  rel="noopener"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    role="img"
                    aria-labelledby="ab42ak1webofw5omjkn3m7am5lyhb10n"
                    class="crayons-icon"
                  >
                    <title id="ab42ak1webofw5omjkn3m7am5lyhb10n">
                      Google-plus
                    </title>
                    <path
                      d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-10.333 16.667c-2.581 0-4.667-2.087-4.667-4.667s2.086-4.667 4.667-4.667c1.26 0 2.313.46 3.127 1.22l-1.267 1.22c-.347-.333-.954-.72-1.86-.72-1.593 0-2.893 1.32-2.893 2.947s1.3 2.947 2.893 2.947c1.847 0 2.54-1.327 2.647-2.013h-2.647v-1.6h4.406c.041.233.074.467.074.773-.001 2.666-1.787 4.56-4.48 4.56zm11.333-4h-2v2h-1.334v-2h-2v-1.333h2v-2h1.334v2h2v1.333z"
                      fill="#4a5568"
                    ></path>
                  </svg>
                </a> -->
                <a
                  :href="jsonMisc.socialMediaLinks.twitter"
                  target="_blank"
                  class="crayons-link crayons-link--secondary mx-1"
                  rel="noopener"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    role="img"
                    aria-labelledby="at9o9t2kxultxzdzepppn59ck47f06fc"
                    class="crayons-icon"
                  >
                    <title id="at9o9t2kxultxzdzepppn59ck47f06fc">Twitter</title>
                    <path
                      d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z"
                      fill="#4a5568"
                    ></path>
                  </svg>
                </a>
                <a
                  :href="jsonMisc.socialMediaLinks.linkedin"
                  target="_blank"
                  class="crayons-link crayons-link--secondary mx-1"
                  rel="noopener"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    role="img"
                    aria-labelledby="at9o9t2kxultxzdzepppn59ck47f06fc"
                    class="crayons-icon"
                  >
                    <title id="at9o9t2kxultxzdzepppn59ck47f06fc">
                      Linkedin
                    </title>
                    <path
                      d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                      fill="#4a5568"
                    ></path>
                  </svg>
                </a>
                <a
                  :href="jsonMisc.socialMediaLinks.instagram"
                  target="_blank"
                  class="crayons-link crayons-link--secondary ml-1"
                  rel="noopener"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    role="img"
                    aria-labelledby="a324dh2axynlcj0cu0wcdnfsuhmcw1af"
                    class="crayons-icon"
                  >
                    <title id="a324dh2axynlcj0cu0wcdnfsuhmcw1af">
                      Instagram
                    </title>
                    <path
                      d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z"
                      fill="#4a5568"
                    ></path>
                  </svg>
                </a>
                <div @click="checkOut()" class="pl-3 no-underline hover:text-black">
                  <div v-if="productsInCart.length" class="h-6 w-6 ml-px absolute animate-ping bg-orange-400 rounded-full" />
                  <span
                    class="absolute w-4 h-4 flex items-center justify-center bg-orange-1100 font-bold text-white rounded-full"
                    style="font-size: 10px; margin-top: -10px; margin-left: 15px;"
                  >{{ productsInCart.length }}</span>
                  <svg
                    class="fill-current hover:text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M21,7H7.462L5.91,3.586C5.748,3.229,5.392,3,5,3H2v2h2.356L9.09,15.414C9.252,15.771,9.608,16,10,16h8 c0.4,0,0.762-0.238,0.919-0.606l3-7c0.133-0.309,0.101-0.663-0.084-0.944C21.649,7.169,21.336,7,21,7z M17.341,14h-6.697L8.371,9 h11.112L17.341,14z"
                    />
                    <circle cx="10.5" cy="18.5" r="1.5" />
                    <circle cx="17.5" cy="18.5" r="1.5" />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <modal ref="contactus-modal-comp" :show-close-btn="true" modal-content-cont-height="" name="comments-modal">
      <div class="max-w-xs rounded overflow-hidden shadow-lg z-50">
        <img class="w-full px-4 pt-5" :src="jsonMisc.logos.mainLogo" alt="Emmkay industries Logo">
        <div class="px-6 pt-4">
          <div class="font-bold text-xl mb-2">{{ jsonMisc.contactUsCard.head }}</div>
          <p class="text-grey-darker">{{ jsonMisc.contactUsCard.body }}</p>
          <p class="text-grey-darker mb-4 italic">(click on below number)</p>
          <a :href="`tel:${jsonMisc.contactUsCard.mobile1}`"><div class="flex justify-center p-2"><PhoneIcon /><span class="text-grey-darker text-left px-1">+91 {{ jsonMisc.contactUsCard.mobile1 }}</span></div></a>
          <a :href="`tel:${jsonMisc.contactUsCard.mobile2}`"><div class="flex justify-center p-2"><PhoneIcon /><span class="text-grey-darker text-left px-1">+91 {{ jsonMisc.contactUsCard.mobile2 }}</span></div></a>
          <a :href="`mailTo:${jsonMisc.contactUsCard.email1}`"><div class="flex justify-center p-2"><MailIcon /><span class="text-grey-darker text-left px-1">{{ jsonMisc.contactUsCard.email1 }}</span></div></a>
          <a :href="`mailTo:${jsonMisc.contactUsCard.email2}`"><div class="flex justify-center p-2"><MailIcon /><span class="text-grey-darker text-left px-1">{{ jsonMisc.contactUsCard.email2 }}</span></div></a>
        </div>
        <div class="py-4">
          <span v-for="(keyWord,index) in jsonMisc.contactUsCard.keyWords" :key="index" class="inline-block bg-grey-lighter rounded-full px-3 py-1 text-sm font-semibold text-grey-darker mr-2">{{ keyWord }}</span>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  PhoneIcon,
  MailIcon,
  ArrowUpIcon,
} from "vue-feather-icons";
import Modal from './modal.vue';

export default {
  name: "Header",
  components: {
    PhoneIcon,
    MailIcon,
    ArrowUpIcon,
    Modal,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  props: {
    productsInCart: Array,
    isCheckout: Boolean,
    isModalOpen: Boolean,
    jsonMisc: Object,
  },
  watch: {
    isModalOpen(val){
      if (val === true) {
        this.$refs['contactus-modal-comp'].$data.isOpen = true;
        this.$parent.isModalOpen = false;
      }
    },
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    openContactUsModal() {
      // console.log('abcd', this.$refs['contactus-modal-comp']);
      this.$refs['contactus-modal-comp'].$data.isOpen = true;
    },
    checkOut() {
      this.$parent.isCheckout = true;
      // console.log("this.$parent.isCheckout ", this.$parent.isCheckout);
    },
    scrollTop() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) clearInterval(this.intervalId);
        window.scroll(0, window.pageYOffset - 50)
      }, 10)
    },
    scrollListener() {
      this.isVisible = window.scrollY > 100
    },
    menuToggle() {
      // console.log("called...");
      let val = document.getElementById('menu').style.display;
      if (!val) val = 'none';
      if(val === 'block') document.getElementById('menu').style.display = 'none'
      else if (val === 'none') document.getElementById('menu').style.display = 'block';
    },
  },
};
</script>
