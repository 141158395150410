<template>
  <div id="app">
    <div v-if="!fetchData">
      <Header :json-misc="jsonMisc" :productsInCart="productsInCart" :isCheckout="isCheckout" :isModalOpen="isModalOpen" />
      <Cart :json-misc="jsonMisc" :is-checkout="isCheckout" :products-in-cart="productsInCart" :my-json="myJson" :my-hot-products="myHotProducts" :my-dshbrd-json="myDshbrdJson" />
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path" :products-in-cart="productsInCart" :my-json="myJson" :my-hot-products="myHotProducts" :my-dshbrd-json="myDshbrdJson" :json-misc="jsonMisc" :isCheckout="isCheckout" />
      </transition>
      <Footer :json-misc="jsonMisc" :isModalOpen="isModalOpen" />
    </div>
    <div v-else class="w-full " style="height: 100vh; padding-top: 40vh">
      <img src="../assets/images/emmkay_logo_1.png" alt="Emmkay industries Logo" class="mx-auto py-auto pb-2 md:pb-0" />
      <div>Loading...</div>
      <RingLoader class="pt-px" width="20px" height="20px" thickness="2px" color="#444444"/>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Header from "@/components/Header";
import Cart from '@/components/Cart.vue';
import Footer from "@/components/Footer";
import RingLoader from '../components/RingLoader.vue';
// json imported =>
// import product from '@/assets/json/product.json';
// import productInfo from '@/assets/json/product-info.json';
// import jsonMisc from "@/assets/json/miscellaneous.json";

export default {
  data() {
    return {
      myJson: [],
      myDshbrdJson: [],
      myHotProducts: [],
      productsInCart: [],
      isCheckout : false,
      isModalOpen : false,
      products : [],
      productInfo : [],
      jsonMisc : {
        "logos": {},
        "crouselImages": [],
        "socialMediaLinks": {},
        "googleMap": {},
        "googleReCaptcha": {},
        "sendEmail": {},
        "aboutUsContent": {},
        "contactUsCard": {},
        "footer": {}
      },
      fetchData : false,
    };
  },
  components: {
    Header,
    Footer,
    Cart,
    RingLoader,
  },
  async mounted() {
    await this.fetchAllData();
    const mainArray = this.products.map((obj) => {
      const productInfoObj = this.productInfo.filter((p) => p.productId === obj.productId);
      obj.productInfo = productInfoObj[0];
      return obj;
    });
    this.myJson = this.myJson.concat(mainArray);
    this.myDshbrdJson = mainArray.filter((obj) => obj.showProductOnDashbrd);
    const newAjx = mainArray.filter((objj) => objj.hotProduct);
    this.myHotProducts = this.myHotProducts.concat(newAjx);
  },
  methods: {
    async fetchAllData(){
      // ---------------------------------- 3 urls of files here--------------------------------------------
      const product_file_url = 'https://s3.ap-south-1.amazonaws.com/www.emmkayindustries.in/three-json-files/allProducts.json';
      const product_info_file_url = 'https://s3.ap-south-1.amazonaws.com/www.emmkayindustries.in/three-json-files/product-info.json';
      const miscellaneous_file_url = 'https://s3.ap-south-1.amazonaws.com/www.emmkayindustries.in/three-json-files/miscellaneous.json';
      // ----------------------------------3 urls of files here--------------------------------------------
      const headers = {
          headers: { 
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods' : 'GET',
          },
          responseType: 'json',
        }
      await this.fetchProducts(product_file_url, headers);
      await this.fetchProductInfo(product_info_file_url, headers);
      await this.fetchMiscellaneous(miscellaneous_file_url, headers);
      if(this.products.length && this.productInfo.length && this.jsonMisc && this.jsonMisc.logos.mainLogo) this.fetchData = false;
    },
    async fetchProducts(product_file_url, headers){
      this.fetchData = true;
      await axios
        .get(product_file_url, headers)
        .then((response) => this.products = response.data)
        .catch((error) => { return error});
    },
    async fetchProductInfo(product_info_file_url, headers){
      this.fetchData = true;
      await axios
        .get(product_info_file_url, headers)
        .then((response) => this.productInfo = response.data)
        .catch((error) => { return error});
    },
    async fetchMiscellaneous(miscellaneous_file_url, headers){
      this.fetchData = true;
      await axios
        .get(miscellaneous_file_url, headers)
        .then((response) => this.jsonMisc = response.data)
        .catch((error) => { return error});
    }
  }
}
</script>
