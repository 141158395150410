<template>
    <section
      v-show="isCheckout"
      class="fixed drawer drawer-cart open flex flex-col right-0 bottom-0 top-0 bg-white shadow-xl-rDrawer z-40"
    >
      <!-- <div class="flex justify-end m-2 text-gray-600 cursor-pointer">
        <X-circle-icon @click.prevent="folderRDrawerOpen = false" class="" size="1.5x" />
      </div> -->
      <div class="flex flex-col w-full h-full">
        <div
          class="w-full border-b flex justify-center items-center relative px-8 py-5 border-gray-200 text-gray-800"
        >
          <button
            @click.prevent="$parent.isCheckout = false, sentEmail = false"
            class="absolute left-0 ml-8 w-auto my-auto flex items-center text-gray-500 transition duration-300 focus:outline-none hover:text-gray-900"
            aria-label="close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19px"
              height="12px"
              viewBox="0 0 18.738 12"
            >
              <path
                data-name="Path 17147"
                d="M1252.468,501.849l5.7-5.922,1.5,1.567-3.395,3.143.091.214h14.849v2.108H1256.2l3.654,3.43-1.558,1.538Z"
                transform="translate(-1252.468 -495.927)"
                fill="#212121"
              ></path>
            </svg>
          </button>
          <h2 class="font-bold text-2xl m-0">Enquiry Cart</h2>
        </div>
        <div class="overflow-y-auto flex-grow">
          <div v-if="productsInCart.length" class="relative overflow-y-auto flex-col w-full h-full m-auto">
            <div
              v-for="(product, index) in productsInCart"
              :key="index"
              class="flex w-full h-auto justify-start items-center bg-white py-6 pl-8 pr-2 border-b border-gray-200 relative"
            >
              <div class="flex w-32 h-32 rounded overflow-hidden bg-gray-200 flex-shrink-0">
                <img :src="getImgUrl(product.imgPath)" :alt="product.productName" />
              </div>
              <div class="flex flex-col text-left w-full pl-6 pr-4">
                <span class="text-sm text-gray-700">{{product.productName}}</span>
                <span class="text-sm text-gray-500 mt-1 mb-3">
                  {{product.offer ? `Offer: ${product.offer}% Discount` : 'No offer available'}}
                </span>
                <div class="flex items-center justify-start">
                  <div
                    v-if="product && product.isAdded && product.qty"
                    class="group shadow-bottom flex items-center justify-center flex-shrink-0 font-normal w-auto uppercase rounded outline-none transition duration-250 ease-in-out focus:outline-none text-white bg-orange-1100 text-sm"
                  >
                    <button
                      v-if="product.qty === product.minOrderQty"
                      @click="deleteFromCart(product)"
                      class="flex items-center justify-center outline-none transition-colors duration-250 rounded-l ease-in-out h-full w-1/3 text-white bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3"
                    >
                      <DeleteIcon />
                    </button>
                    <button
                      v-else
                      @click="productQtySub(product)"
                      class="flex items-center justify-center outline-none transition-colors duration-250 ease-in-out h-full rounded-l w-1/3 text-white bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3 py-4"
                    >
                      <div class="py-px">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12px"
                          height="2px"
                          viewBox="0 0 12 1.5"
                        >
                          <rect
                            data-name="Rectangle 970"
                            width="12px"
                            height="12px"
                            fill="currentColor"
                          ></rect>
                        </svg>
                      </div>
                    </button>
                    <input
                      type="number"
                      :placeholder="product.minOrderQty"
                      class="font-semibold text-sm flex items-center text-center justify-center h-full w-10 transition-colors duration-250 ease-in-out cursor-default placeholder-white rounded outline-none duration-200 text-white bg-orange-1100 focus:outline-none"
                      :id="'qtyE' + product.productId"
                      :ref="'qtyE' + product.productId"
                      autocomplete="off"
                      name="qtyE"
                      @change="changeOrderQty($event, product)"
                      v-model="product.qty"
                    />
                    <!-- <span class="font-semibold text-13px text-white flex items-center justify-center h-full w-10 transition-colors duration-250 ease-in-out cursor-default">{{ product.qty }}</span> -->
                    <button
                      @click="productQtyAdd(product)"
                      class="flex items-center justify-center outline-none transition-colors duration-250 ease-in-out h-full rounded-r w-1/3 text-white bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3"
                    >
                      <svg
                        data-name="plus (2)"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12px"
                        height="12px"
                        viewBox="0 0 12 12"
                      >
                        <g data-name="Group 5367">
                          <path
                            data-name="Path 17138"
                            d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                            fill="currentColor"
                          ></path>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <!-- <span class="font-semibold text-16px text-gray-900 flex-shrink-0">$10.00</span> -->
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="sentEmail" class="relative overflow-y-auto flex-col w-full h-full m-auto">
            <div class="flex-auto">
              <div v-if="sentEmail" class="flex items-center justify-center">
                <img src="../assets/images/thanks.gif" alt="thanks" class="w-1/2 pt-20">
              </div>
              <p v-if="sentEmail" class="text-center text-gray-900 px-2 py-5 md:p-10 leading-loose">
                <b>Thank you for your valuable enquiry, we would get in touch with you shortly.</b>
              </p>
            </div>
          </div>
          <div v-else class="relative overflow-y-auto flex-col w-full h-full m-auto">
            <div class="flex-auto">
              <p v-if="!sentEmail" class="text-center text-gray-900 px-2 py-5 md:p-10 leading-loose">
                You haven't added any product in your cart yet. Choose the products to continue.
              </p>
              <div v-if="!sentEmail" class="flex items-center justify-center">
                <BlankBox />
              </div>
            </div>
          </div>
        </div>
        <div v-if="isEnquired" class="flex flex-col px-8 pt-5">
          <div class="flex flex-col mb-45px">
            <div class="flex justify-between items-center">
              <span class="flex font-semibold text-gray-900 text-lg">Contact Information</span>
              <X-circle-icon
                @click.prevent="isEnquired = false"
                class="text-gray-900"
                size="1.2x"
              />
            </div>
            <div class="flex items-center mt-4">
              <label class="sr-only">name</label>
              <input
                type="text"
                placeholder="Your name"
                :class="[errorName ? 'border-red-700 placeholder-red-500' : 'border-transparent placeholder-gray-500']"
                class="h-12 px-4 w-full border rounded outline-none transition duration-200 text-gray-900 bg-gray-200 hover:border-gray-400 focus:border-black focus:placeholder-gray-900"
                id="name"
                autocomplete="off"
                name="name"
                v-model="name"
                style="width: calc(50% - 5px); margin-right: 5px"
                @blur="removeError()"
              />
              <label class="sr-only">mobile</label>
              <input
                type="text"
                inputmode="numeric"
                placeholder="Mobile Number"
                :class="[errorMobile ? 'border-red-700 placeholder-red-500' : 'border-transparent placeholder-gray-500']"
                class="h-12 px-4 w-full border rounded outline-none transition duration-200 text-gray-900 bg-gray-200 hover:border-gray-400 focus:border-black focus:placeholder-gray-900"
                id="mobile"
                autocomplete="off"
                name="mobile"
                v-model="mobile"
                style="width: calc(50% - 5px); margin-left: 5px"
                @blur="removeError()"
              />
            </div>
            <label class="sr-only">email</label>
            <input
              type="text" 
              name="email" 
              id="email"
              v-model="email"
              placeholder="Email Address"
              :class="[errorEmail ? 'border-red-700 placeholder-red-500' : 'border-transparent placeholder-gray-500']"
              class="h-12 mt-2 w-full px-4 border rounded outline-none transition duration-200 text-gray-900 bg-gray-200 hover:border-gray-400 focus:border-black focus:placeholder-gray-900"
              autocomplete="off"
              @blur="removeError()"
            />
            <label class="sr-only">Special Requirements / Instructions</label>
            <input
              type="text"
              name="spclReq"
              id="spclReq"
              v-model="spclReq"
              placeholder="Special Requirement/Instruction (Optional)"
              class="h-12 mt-2 mb-4 w-full px-4 border rounded outline-none transition duration-200 text-gray-900 bg-gray-200 hover:border-gray-400 focus:border-black focus:placeholder-gray-900 border-transparent placeholder-gray-500"
              autocomplete="off"
              @blur="removeError()"
            />
          </div>
          <!-- reCAPTCHA -->
          <div class="flex justify-center border" :class="[errorCaptcha ? 'border-red-700' : 'border-transparent']">
            <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="jsonMisc.googleReCaptcha.siteKey" :loadRecaptchaScript="true"></vue-recaptcha>
          </div>
          <div class="flex flex-col pb-8 bottom-0">
            <i class="font-semibold text-sm text-left text-gray-900"
              >{{
                productsInCart && productsInCart.length > 1
                  ? productsInCart.length + " products"
                  : productsInCart.length + " product"
              }}
              information attached herewith
            </i>
            <button
              class="flex items-center justify-center flex-shrink-0 font-normal w-auto uppercase rounded outline-none transition duration-250 ease-in-out focus:outline-none text-white bg-orange-1100 duration-300 hover:bg-orange-400 h-10 px-8 big mt-5"
              type="button"
              :disabled="waitLoader?true:false"
              aria-label="button"
              @click="validateForm()"
            >
              <RingLoader v-if="waitLoader" class="pt-px" width="20px" height="20px" thickness="2px" />
              <span v-if="!waitLoader">Submit</span>
            </button>
          </div>
        </div>
        <div v-else class="flex flex-col p-8 bottom-0">
          <span v-if="productsInCart.length" class="font-semibold text-sm text-left text-gray-900">Click on below button for enquiry</span>
          <button
            v-if="productsInCart.length" 
            @click="isEnquired = true"
            class="flex items-center justify-center flex-shrink-0 font-normal w-auto uppercase rounded outline-none transition duration-250 ease-in-out focus:outline-none h-10 px-8 big mt-5 bg-orange-1100 duration-300 hover:bg-orange-500 text-white cursor-pointer"
            type="button"
            aria-label="button"
          >
            Enquiry
          </button>
          <button
            v-if="sentEmail && !productsInCart.length" 
            @click.prevent="$parent.isCheckout = false, sentEmail = false"
            class="bg-orange-1100 duration-300 hover:bg-orange-400 text-white cursor-pointer flex items-center justify-center flex-shrink-0 font-normal w-auto rounded outline-none transition duration-250 ease-in-out focus:outline-none h-10 px-8 big mt-5"
            type="button"
            aria-label="button"
          >
            Explore More Products
          </button>
        </div>
      </div>
    </section>
</template>
<script>
import { XCircleIcon } from "vue-feather-icons";
import VueRecaptcha from 'vue-recaptcha';
import BlankBox from '../components/BlankBox.vue';
import jsonData from "@/assets/json/miscellaneous.json";
import DeleteIcon from "@/components/DeleteIcon.vue";
import axios from 'axios';
import RingLoader from './RingLoader.vue';

export default {
  name: 'cart',
  data() {
    return {
      name:'',
      mobile: '',
      email: '',
      spclReq: '',
      reCaptchaResp: '',
      errorName: false,
      errorMobile: false,
      errorEmail: false,
      errorCaptcha: false,
      sentEmail: false,
      isEnquired: false,
      verifiedCaptcha: false,
      waitLoader: false,
    };
  },
  components: {
    XCircleIcon,
    BlankBox,
    DeleteIcon,
    VueRecaptcha,
    RingLoader
  },
  props: {
    productsInCart: Array,
    myDshbrdJson: Array,
    myHotProducts: Array,
    isCheckout: Boolean,
    jsonMisc: Object,
    myJson: Array
  },
  watch: {},
  methods: {
    getImgUrl(imgPath) {
      return imgPath;
    },
    changeOrderQty(event, product) {
      let changedQty = parseInt(event.target.value, 10);
      !changedQty || Number.isNaN(changedQty) ? (changedQty = 1) : changedQty;
      // console.log("changedQty", changedQty);
      const updatedProducts = this.productsInCart.map((obj) => {
        if (obj.productId === product.productId) {
          if (changedQty <= product.minOrderQty) {
            obj.qty = product.minOrderQty;
            return obj;
          } else {
            obj.qty = changedQty;
            return obj;
          }
        }
        return obj;
      });
      this.$parent.productsInCart = updatedProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;
      const updateProducts = this.myDshbrdJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myDshbrdJson = updateProducts;
      // return product.qty;
    },
    productQtyAdd(product) {
      const updatedProducts = this.productsInCart.map((obj) => {
        // console.log("qty1", obj.productId, product.productId);
        if (obj.productId === product.productId) {
          obj.qty += 1;
        }
        return obj;
      });
      this.$parent.productsInCart = updatedProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;
      const updateProducts = this.myDshbrdJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myDshbrdJson = updateProducts;
    },
    productQtySub(product) {
      const updatedProducts = this.productsInCart.map((obj) => {
        // console.log("qty1", obj.productId, product.productId);
        if (obj.productId === product.productId && obj.qty > obj.minOrderQty) {
          obj.qty -= 1;
        }
        return obj;
      });
      this.$parent.productsInCart = updatedProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;
      const updateProducts = this.myDshbrdJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myDshbrdJson = updateProducts;
    },
    deleteFromCart(product) {
      product.isAdded = false;
      // this.productsInCart.splice(index, 1);
      const restProducts = this.productsInCart.filter(
        (allproduct) => allproduct.productId !== product.productId
      );
      this.$parent.productsInCart = restProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;
      const updateProducts = this.myDshbrdJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myDshbrdJson = updateProducts;
      // console.log("added to cart", this.productsInCart);
    },
    onVerify(response) {
      // console.log('Verify: ' + response);
      if (response) {
        this.reCaptchaResp = response;
        this.verifiedCaptcha = true;
        this.errorCaptcha = false;
      }
    },
    onExpired() {
      // console.log('Expired');
      this.verifiedCaptcha = false;
    },
    resetRecaptcha () {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
    handleError() {
      if (this.name === '') {
        this.errorName = true;
        this.errorMobile = false;
        this.errorEmail = false;
        return false;
      }
      if (this.mobile === '' || !/^[0-9]{10,20}$/.test(parseInt(this.mobile, 10))) {
        this.errorMobile = true;
        this.errorName = false;
        this.errorEmail = false;
        return false;
      }
      if (this.email === '' || !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email)) {
        this.errorEmail = true;
        this.errorName = false;
        this.errorMobile = false;
        return false;
      }
      if (!this.verifiedCaptcha) {
        this.errorCaptcha = true;
        return false;
      }
      return true;
    },
    removeError(){
      this.errorName = false;
      this.errorMobile= false;
      this.errorEmail= false;
      // this.errorCaptcha= false;
    },
    async validateForm() {
      const isError = this.handleError();
      if(isError){
        this.waitLoader = true;
        const updatedProducts1 = this.productsInCart.map((obj) => {
          const productHtml = `
            <div style="display: flex; max-width: 384px; position: relative; height: auto; justify-content: flex-start; align-items: center; background-color: #f6f6f6; border-radius: 0.5rem" class="flex w-full h-auto justify-start items-center bg-white py-6 pl-8 pr-2 border-b border-gray-200 relative">
              <div style="display: flex; width: 128px; height: 128px; border-radius: 0.5rem; background-color: rgba(237, 242, 247, 1); flex-shrink: 0;" class="flex w-32 h-32 rounded overflow-hidden bg-gray-200 flex-shrink-0">
                <img style="border-bottom-left-radius: 0.5rem; border-top-left-radius: 0.5rem" src="${obj.imgPath}" alt="${obj.productName}">
              </div>
              <div style="width: 100%; text-align: left; padding: 0.5rem 1rem 0.5rem 1.5rem" class="flex flex-col text-left w-full pl-6 pr-4">
                <div style="font-size: 1rem; color: rgba(74, 85, 104, 1)" class="text-sm text-gray-700"><b>${obj.productName}</b></div>
                <div style="font-size: 0.875rem; color: rgba(160, 174, 192, 1)" class="text-sm text-gray-500 mt-1 mb-3">${obj.offer ? `Offer: ${obj.offer}% Discount` : 'No offer available'}</div>
                <div style="font-size: 0.875rem; color: rgba(74, 85, 104, 1)" class="text-sm text-gray-500 mt-1 mb-3">Ordered Qty: <b>${obj.qty}</b></div>
              </div>
            </div>`
          // console.log('obj', abc);
          return productHtml;
        });
        const Data = {
          to: jsonData.sendEmail.to, 
          from :jsonData.sendEmail.from, 
          subject: jsonData.sendEmail.subject,
          reCaptchaResp: this.reCaptchaResp,
          emailBody: `Hello Admin,<br><br>Here you have an enquiry for product/s:<br>
            <div style="display: flex; position: relative; padding: 1rem 0rem; height: auto; justify-content: flex-start; align-items: center" class="flex w-full h-auto justify-start items-center bg-white py-6 pl-8 pr-2 border-b border-gray-200 relative">
              <div style="display: flex; border-radius: 100%; width: 84px; height: 84px; background-color: rgba(237, 242, 247, 1); flex-shrink: 0;" class="flex w-32 h-32 rounded overflow-hidden bg-gray-200 flex-shrink-0">
                <img style="border-radius: 100%" src="https://stafforgserv.com.au/wp-content/uploads/2018/09/user-img.png" alt="user image">
              </div>
              <div style="width: 100%; text-align: left; padding-left: 1.5rem; padding-right: 1rem" class="flex flex-col text-left w-full pl-6 pr-4">
                <div style="font-size: 0.875rem; color: rgba(74, 85, 104, 1)" class="text-sm text-gray-500 mt-1 mb-3">Customer name: <b>${this.name}</b></div>
                <div style="font-size: 0.875rem; color: rgba(74, 85, 104, 1)" class="text-sm text-gray-500 mt-1 mb-3">Mobile no.: <b>${this.mobile}</b></div>
                <div style="font-size: 0.875rem; color: rgba(74, 85, 104, 1)" class="text-sm text-gray-500 mt-1 mb-3">Email id: <b>${this.email}</b></div>
                <div style="font-size: 0.875rem; color: rgba(74, 85, 104, 1)" class="text-sm text-gray-500 mt-1 mb-3">Special Requirements / Instructions: <b>${this.spclReq.length ? this.spclReq :'N/A'}</b></div>
              </div>
            </div>
            <div style="background-color: #ffffff; padding: 1rem 0rem">
              ${updatedProducts1}
            </div>
            <br><br>Thanks & Regards,<br> Team Emmkay Industries.`,
        };
        // console.log('obj', Data);
        await axios.post(jsonData.sendEmail.sendEmailApiUrl, Data)
        .then((response) => {
          // console.warn('response', response.data);
          if (response.data.message) {
            this.sentEmail = true;
            this.$parent.productsInCart = [];
            this.isEnquired = false;
            this.resetAllData();
            this.name = '';
            this.mobile = '';
            this.email = '';
            this.spclReq = '';
            this.errorName = false;
            this.errorMobile= false;
            this.errorEmail= false;
            this.errorCaptcha= false;
            // this.$refs.recaptcha.execute();
          }
        })
        .catch((error) => {console.warn('error', error)});
        this.waitLoader = false;
      }
    },
    resetAllData(){
      const myHotProducts = this.myHotProducts.map((obj) => {
        obj.isAdded = false;
        return obj;
      });
      // console.log('myHotProducts', myHotProducts);
      this.$parent.myHotProducts = myHotProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        obj.isAdded = false;
        return obj;
      });
      // console.log('myJson', updatedMyJson);
      this.$parent.myJson = updatedMyJson;
      const updateProducts = this.myDshbrdJson.map((obj) => {
        obj.isAdded = false;
        return obj;
      });
      // console.log('myDshbrdJson', updateProducts);
      this.$parent.myDshbrdJson = updateProducts;
    }
  }
}
</script>